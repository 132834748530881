import { defaultConfig } from './default';
import { AppConfig } from '.';

export const nerdWalletConfig: AppConfig = {
  ...defaultConfig,

  brandName: 'NerdWallet',
  cardName: 'NerdUp Card',
  images: {
    logoDimensions: {
      height: 23,
      width: 141.33,
    },
  },
  legalese: {
    ...defaultConfig.legalese,
    brandPrivacy: {
      isPdf: false,
      link: 'https://www.nerdwallet.com/p/privacy-policy',
      text: 'Privacy Policy',
    },
    brandTerms: {
      isPdf: false,
      link: 'https://www.nerdwallet.com/p/terms-of-use',
      text: 'Terms of Use',
    },
    client: [
      {
        isPdf: true,
        link: 'https://embedded-cbc-dev.s3.us-east-2.amazonaws.com/nerdwallet/nerdwallet-rewards-program-terms.docx.pdf',
        text: 'Rewards Program Terms & Conditions',
      },
      {
        isPdf: false,
        link: 'https://www.nerdwallet.com/p/privacy-policy',
        text: 'Privacy Policy',
      },
    ],
    rewards: {
      isPdf: true,
      link: 'https://embedded-cbc-dev.s3.us-east-2.amazonaws.com/nerdwallet/nerdwallet-rewards-program-terms.docx.pdf',
      name: 'NerdWallet',
      text: 'Rewards Program Terms & Conditions',
    },
  },
  sponsorBank: {
    name: 'Evolve',
    fullName: 'Evolve Bank & Trust, Member FDIC',
    bank: {
      isPdf: true,
      link: 'https://embedded-cbc-dev.s3.us-east-2.amazonaws.com/nerdwallet/evolve-secured-account-charge-card-account-disclosures-agreement.docx.pdf',
      text: 'Secured Account and Charge Card Account Disclosures and Agreement',
    },
    communications: {
      isPdf: true,
      link: 'https://embedded-cbc-dev.s3.us-east-2.amazonaws.com/nerdwallet/evolve-electronic-communication-consent.docx.pdf',
      text: 'Electronic Communication Consent',
    },
    privacy: {
      link: 'https://www.getevolved.com/privacy-policy/',
      text: 'Privacy Policy',
    },
  },
  minimumInitialDepositInDollars: 100,
  publicPath: '/nerd-wallet',
  sdaName: 'NerdUp Security Deposit Account',
  supportEmail: 'cardsupport@nerdwallet1.zendesk.com',
  supportPhone: '(855) 540-8760',
  theme: {
    ...defaultConfig.theme,

    button: {
      ...defaultConfig.theme.button,
      primaryBackgroundColor: '#008254',
    },
    cardDetail: {
      cardVerticalOffset: '18px',
      fontColor: 'black',
    },
    loadingBackgroundColor: '#00825499',
    modal: {
      backgroundColor: '#00825499',
    },
    optInButton: {
      backgroundColorNoValue: '#008254',
      backgroundColorValue: '#00825499',
    },
  },
};
