import { Dispatch, SetStateAction } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTheme } from 'styled-components';

import { getSdaAccount } from 'apps/embedded-cbc/api/accounts';
import {
  Box,
  Button,
  CopyButton,
  Modal,
  Spinner,
  Text,
} from 'apps/embedded-cbc/components';
import { fixtures } from './fixtures';
import logGtmEvent, {
  GTM_EVENTS,
} from 'apps/embedded-cbc/utils/gtm-event-logger';
import { useAuthContext } from 'apps/embedded-cbc/contexts/auth';
import { AppConfig } from 'apps/embedded-cbc/config';

interface Props {
  config: AppConfig;
  isVisible: boolean;
  sdaName: string;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
}

export const DepositInfo = ({
  config,
  isVisible,
  sdaName,
  setIsVisible,
}: Props) => {
  const { loadingBackgroundColor } = useTheme();
  const { user } = useAuthContext();
  const { sponsorBank } = config;
  const { data, isLoading } = useQuery({
    queryFn: () => getSdaAccount(),
    queryKey: ['sda'],
    refetchOnWindowFocus: false,
  });

  return (
    <Modal
      isOpen={isVisible}
      onRequestClose={() => setIsVisible(false)}
      style={{
        content: {
          height: 'calc(100svh - 30px)',
          inset: '15px',
          maxHeight: '650px',
          padding: '20px',
        },
      }}
    >
      {isLoading && (
        <Spinner
          color={loadingBackgroundColor}
          size={100}
          sx={{ margin: '30% auto 0' }}
        />
      )}
      {!isLoading && (
        <>
          <Text variant="h5">Use deposit account & routing numbers</Text>
          <Text sx={{ marginTop: '16px' }}>
            Log in to your existing bank or financial service provider’s app or
            website.
          </Text>
          <Text sx={{ marginTop: '16px' }}>
            Each app or website is different - follow its instructions to send
            money to your {sdaName} using the numbers below.
          </Text>
          <Box
            sx={{
              backgroundColor: '#F9F9FC',
              margin: '16px -20px',
              padding: '16px 40px',
            }}
          >
            <Text variant="caption">Routing number</Text>
            <Text sx={{ display: 'inline-block', marginRight: '12px' }}>
              {data?.routing_number}
            </Text>
            <CopyButton
              valueToCopy={
                data?.routing_number ?? 'Strange, there is no routing number'
              }
            />

            <Text sx={{ marginTop: '12px' }} variant="caption">
              Account number
            </Text>
            <Text
              sx={{
                display: 'inline-block',
                fontFamily: 'Roboto, sans-serif',
                marginRight: '12px',
              }}
            >
              {data?.account_number}
            </Text>
            <CopyButton
              valueToCopy={
                data?.account_number ?? 'Strange, there is no account number'
              }
            />

            <Text sx={{ marginTop: '16px' }} variant="caption">
              Bank
            </Text>
            <Text>{sponsorBank.fullName}</Text>

            <Text sx={{ marginTop: '16px' }} variant="caption">
              Account type
            </Text>
            <Text>Checking</Text>

            <Text sx={{ marginTop: '16px' }} variant="caption">
              Find your account & routing numbers anytime in your{' '}
              <i>Deposit account</i> page.
            </Text>
          </Box>

          <Button
            isFullWidth
            onClick={() => {
              setIsVisible(false);
              logGtmEvent(GTM_EVENTS.add_money_account_info_close, {
                customer_id: user?.customer_id,
              });
            }}
            sx={{ marginTop: '12px' }}
          >
            <strong>{fixtures.buttonCta}</strong>
          </Button>
        </>
      )}
    </Modal>
  );
};
